import React, { useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
// import { Conversation } from '@twilio/conversations';
import { Channel, Message as pnMessage, MessageDraft, User } from '@pubnub/chat';
import FileAttachmentIcon from '../../../icons/FileAttachmentIcon';
import { isMobile } from '../../../utils';
import SendMessageIcon from '../../../icons/SendMessageIcon';
import Snackbar from '../../Snackbar/Snackbar';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const useStyles = makeStyles(theme => ({
  chatInputContainer: {
    // borderTop: '1px solid #e4e7e9',
    // borderBottom: '1px solid #e4e7e9',
    //padding: '1em 1.2em 1em',
    alignItems: 'center',
  },
  textArea: {
    width: '100%',
    //border: '0',
    padding: '8px 14px',
    border: '1px solid #72879A',
    borderRadius: '16px',
    resize: 'none',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '21px',
    fontFamily: 'Poppins',
    outline: 'none',
  },
  button: {
    padding: '0 0.56em', //'0.56em',
    minWidth: 'auto',
    '&:disabled': {
      background: 'none',
      '& path': {
        fill: '#d8d8d8',
      },
    },
  },
  buttonContainer: {
    //margin: '1em 0 0 1em',
    display: 'flex',
  },
  fileButtonContainer: {
    position: 'relative',
    marginRight: '1em',
  },
  fileButtonLoadingSpinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  textAreaContainer: {
    display: 'flex',
    marginTop: '0.4em',
    padding: '0.48em 0.7em',
    //border: '2px solid transparent',
  },
  isTextareaFocused: {
    borderColor: theme.palette.primary.main,
    borderRadius: '4px',
  },
}));

interface ChatInputProps {
  activeChannel: Channel | null;
  replyInThread?: any;
  //conversation?: Conversation;
  quotedMessage?: any;
  setQuotedMessage?: any;
  isChatWindowOpen: boolean;
  // currentUser: User | null;
  // groupUsers?: any;
  // groupMembership: any;
  // messages: pnMessage[];
  // usersHaveChanged?: any;
}

const ALLOWED_FILE_TYPES =
  'audio/*, image/*, text/*, video/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document .xslx, .ppt, .pdf, .key, .svg, .csv';

export default function ChatInput({
  activeChannel,
  replyInThread /*conversation, */,
  quotedMessage,
  setQuotedMessage = (any: any) => {},
  isChatWindowOpen,
}: ChatInputProps) {
  const classes = useStyles();
  const [messageBody, setMessageBody] = useState('');
  const [newMessageDraft, setNewMessageDraft] = useState<MessageDraft>();
  const [suggestedUsers, setSuggestedUsers] = useState<User[]>([]);
  const [nameOccurrenceIndex, setNameOccurrenceIndex] = useState<number>(-1);
  const [suggestedChannels, setSuggestedChannels] = useState<Channel[]>([]);
  const [channelOccurrenceIndex, setChannelOccurrenceIndex] = useState<number>(-1);

  const inputRef = useRef<HTMLInputElement>(null);
  const [hasAttachment, setHasAttachment] = useState(false);
  const [isSendingFile, setIsSendingFile] = useState(false);
  const [fileSendError, setFileSendError] = useState<string | null>(null);
  const isValidMessage = /\S/.test(messageBody);
  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);

  // direct1020061957693330

  useEffect(() => {
    if (isChatWindowOpen) {
      // When the chat window is opened, we will focus on the text input.
      // This is so the user doesn't have to click on it to begin typing a message.
      //textInputRef.current?.focus();
    }
  }, [isChatWindowOpen]);

  // const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
  //   setMessageBody(event.target.value);
  // };

  const actionCompleted = (a: any) => {};

  async function handleSend(event: React.SyntheticEvent) {
    event.preventDefault();
    if (!messageBody || !newMessageDraft || !activeChannel) {
      return;
    }
    if (replyInThread) {
      //  This demo only supports text replies in the thread UI
      await activeChannel.sendText(messageBody, { storeInHistory: true });
      setMessageBody('');
    } else {
      if (quotedMessage) {
        newMessageDraft.addQuote(quotedMessage);
      }
      await newMessageDraft.send({ storeInHistory: true });
      setNewMessageDraft(
        activeChannel?.createMessageDraft({
          userSuggestionSource: 'channel',
          isTypingIndicatorTriggered: activeChannel.type !== 'public',
          userLimit: 6,
          channelLimit: 6,
        })
      );
      setHasAttachment(false);
      setQuotedMessage(false);
      setMessageBody('');

      actionCompleted({
        action: 'Send a Chat Message',
        blockDuplicateCalls: false,
        debug: false,
      });
    }
  }

  async function handleTyping(e: React.ChangeEvent<HTMLTextAreaElement>) {
    if (activeChannel?.type !== 'public') {
      activeChannel?.startTyping();
    }
    //setText(e.target.value);
    setMessageBody(e.target.value);
    const response = await newMessageDraft?.onChange(e.target.value);
    if ((response?.users.suggestedUsers.length ?? 0) > 0) {
      setSuggestedUsers(response!.users.suggestedUsers);
      setNameOccurrenceIndex(response!.users.nameOccurrenceIndex);
    } else {
      setSuggestedUsers([]);
      setNameOccurrenceIndex(-1);
    }
    if ((response?.channels.suggestedChannels.length ?? 0) > 0) {
      setSuggestedChannels(response!.channels.suggestedChannels);
      setChannelOccurrenceIndex(response!.channels.channelOccurrenceIndex);
    } else {
      setSuggestedChannels([]);
      setChannelOccurrenceIndex(-1);
    }
  }

  // ensures pressing enter + shift creates a new line, so that enter on its own only sends the message:
  const handleReturnKeyPress = (event: React.KeyboardEvent) => {
    if (!isMobile && event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage(messageBody);
    }
  };

  const handleSendMessage = (message: string) => {
    if (isValidMessage) {
      activeChannel?.sendText(message.trim());
      //chatClient.sendMessage(message.trim());
      //conversation.sendMessage(message.trim());
      setMessageBody('');
    }
  };

  const handleSendFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const file = event.target.files?.[0];
    // if (file) {
    //   var formData = new FormData();
    //   formData.append('userfile', file);
    //   setIsSendingFile(true);
    //   setFileSendError(null);
    //   conversation
    //     .sendMessage(formData)
    //     .catch((e: Error) => {
    //       if (e.code === 413) {
    //         setFileSendError('File size is too large. Maximum file size is 150MB.');
    //       } else {
    //         setFileSendError('There was a problem uploading the file. Please try again.');
    //       }
    //       console.log('Problem sending file: ', e);
    //     })
    //     .finally(() => {
    //       setIsSendingFile(false);
    //     });
    // }
  };

  useEffect(() => {
    if (!activeChannel) return;
    setNewMessageDraft(
      activeChannel.createMessageDraft({
        userSuggestionSource: 'channel',
        isTypingIndicatorTriggered: activeChannel.type !== 'public',
        userLimit: 6,
        channelLimit: 6,
      })
    );
  }, [activeChannel]);

  return (
    <div className={classes.chatInputContainer}>
      <Snackbar
        open={Boolean(fileSendError)}
        headline="Error"
        message={fileSendError || ''}
        variant="error"
        handleClose={() => setFileSendError(null)}
      />
      <div className={clsx(classes.textAreaContainer, { [classes.isTextareaFocused]: isTextareaFocused })}>
        {/*
        Here we add the "isTextareaFocused" class when the user is focused on the TextareaAutosize component.
        This helps to ensure a consistent appearance across all browsers. Adding padding to the TextareaAutosize
        component does not work well in Firefox. See: https://github.com/twilio/twilio-video-app-react/issues/498
        */}
        <TextareaAutosize
          minRows={1}
          maxRows={3}
          className={classes.textArea}
          aria-label="chat input"
          placeholder="Write a message..."
          onKeyPress={handleReturnKeyPress}
          onChange={handleTyping}
          value={messageBody}
          data-cy-chat-input
          ref={textInputRef}
          onFocus={() => setIsTextareaFocused(true)}
          onBlur={() => setIsTextareaFocused(false)}
        />
        <button
          className={classes.button}
          //onClick={() => handleSendMessage(messageBody)}
          onClick={handleSend}
          //color="primary"
          //variant="contained"
          disabled={!isValidMessage}
          data-cy-send-message-button
        >
          {/*<SendMessageIcon />*/}
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#FF7557" />
            <path
              d="M21.0607 9.93934C20.4749 9.35355 19.5251 9.35355 18.9393 9.93934L9.3934 19.4853C8.80761 20.0711 8.80761 21.0208 9.3934 21.6066C9.97918 22.1924 10.9289 22.1924 11.5147 21.6066L20 13.1213L28.4853 21.6066C29.0711 22.1924 30.0208 22.1924 30.6066 21.6066C31.1924 21.0208 31.1924 20.0711 30.6066 19.4853L21.0607 9.93934ZM18.5 29C18.5 29.8284 19.1716 30.5 20 30.5C20.8284 30.5 21.5 29.8284 21.5 29L18.5 29ZM18.5 11L18.5 29L21.5 29L21.5 11L18.5 11Z"
              fill="white"
            />
          </svg>
        </button>
      </div>

      <Grid container alignItems="flex-end" justifyContent="flex-end" wrap="nowrap">
        {/* Since the file input element is invisible, we can hardcode an empty string as its value.
        This allows users to upload the same file multiple times. */}
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
          onChange={handleSendFile}
          value={''}
          accept={ALLOWED_FILE_TYPES}
        />
        <div className={classes.buttonContainer}>
          {/*<div className={classes.fileButtonContainer}>
            <Button className={classes.button} onClick={() => fileInputRef.current?.click()} disabled={isSendingFile}>
              <FileAttachmentIcon />
            </Button>

            {isSendingFile && <CircularProgress size={24} className={classes.fileButtonLoadingSpinner} />}
          </div>*/}

          <button
            //className={`${classes.button} tbk-hidden`}
            //onClick={() => handleSendMessage(messageBody)}
            onClick={handleSend}
            //color="primary"
            //variant="contained"
            disabled={!isValidMessage}
            style={{ display: 'none' }}
            //data-cy-send-message-button
          >
            {/*<SendMessageIcon />*/}
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="40" height="40" rx="20" fill="#FF7557" />
              <path
                d="M21.0607 9.93934C20.4749 9.35355 19.5251 9.35355 18.9393 9.93934L9.3934 19.4853C8.80761 20.0711 8.80761 21.0208 9.3934 21.6066C9.97918 22.1924 10.9289 22.1924 11.5147 21.6066L20 13.1213L28.4853 21.6066C29.0711 22.1924 30.0208 22.1924 30.6066 21.6066C31.1924 21.0208 31.1924 20.0711 30.6066 19.4853L21.0607 9.93934ZM18.5 29C18.5 29.8284 19.1716 30.5 20 30.5C20.8284 30.5 21.5 29.8284 21.5 29L18.5 29ZM18.5 11L18.5 29L21.5 29L21.5 11L18.5 11Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </Grid>
    </div>
  );
}
