import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Link } from '@material-ui/core';
import linkify from 'linkify-it';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import readIcon from './read.svg';
import sentIcon from './sent.svg';

const useStyles = makeStyles({
  messageContainer: {
    position: 'relative',
    borderRadius: '8px',
    display: 'inline-flex',
    alignItems: 'center',
    // flexGrow: 1,
    padding: '0.5em 0.8em 0.6em',
    // margin: '0.3em 0 0',
    margin: '0.3em 0',
    wordBreak: 'break-word',
    //backgroundColor: '#72879A',//'#E1E3EA',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    maxWidth: '80%',
    boxShadow: '0px 4px 20px 0px #0000001A',
  },
  isLocalParticipant: {
    backgroundColor: '#72879A', //'#CCE4FF',
    color: 'white',
    marginLeft: 'auto',
  },
});

interface TextMessageProps {
  body: string;
  received?: any;
  dateCreated?: string;
  readReceipts?: string;
  showReadIndicator?: boolean; // = true,
  isLocalParticipant: boolean;
  currentUserId?: string;
  message?: any;
  senderName?: string;
}

function addLinks(text: string) {
  const matches = linkify().match(text);
  if (!matches) return text;

  const results = [];
  let lastIndex = 0;

  matches.forEach((match, i) => {
    results.push(text.slice(lastIndex, match.index));
    results.push(
      <Link target="_blank" rel="noreferrer" href={match.url} key={i}>
        {match.text}
      </Link>
    );
    lastIndex = match.lastIndex;
  });

  results.push(text.slice(lastIndex, text.length));

  return results;
}

export default function TextMessage({
  body,
  received,
  dateCreated,
  readReceipts,
  showReadIndicator = true,
  isLocalParticipant,
  currentUserId,
  message,
  senderName,
}: TextMessageProps) {
  const classes = useStyles();

  // let messageBody = body.text;

  //  Originally I was not writing the 'lastTimetoken' for messages I was sending myself, however
  //  that caused the Chat SDK's notion of an unread message count inconsistent, so I am removing
  //  readReceipts I set myself in this useCallback
  const determineReadStatus = useCallback((timetoken, readReceipts) => {
    if (!readReceipts) {
      return false;
    }
    let returnVal = false;
    for (var i = 0; i < Object.keys(readReceipts).length; i++) {
      const receipt = Object.keys(readReceipts)[i];
      const findMe = readReceipts[receipt].indexOf(currentUserId);
      if (findMe > -1) {
        readReceipts[receipt].splice(findMe, 1);
      }
      if (readReceipts[receipt].length > 0 && receipt >= timetoken) {
        return true;
      }
    }
    return false;
  }, []);

  return (
    <div
      className={clsx(classes.messageContainer, {
        [classes.isLocalParticipant]: isLocalParticipant,
      })}
    >
      {!received && showReadIndicator && (
        <img
          src={determineReadStatus(message?.timetoken, readReceipts) ? readIcon : sentIcon}
          alt="Read"
          className="tbk-absolute tbk-right-0 tbk-top-0"
          // width={21}
          // height={10}
          //priority
        />
      )}
      <div className="tbk-grow tbk-ml-4">
        {!isLocalParticipant ? (
          <Typography variant="body1" color="error">
            {senderName}
          </Typography>
        ) : null}
        <div className="tbk-text-secondary">{addLinks(body)}</div>
        <div className="tbk-text-right">
          <div className="tbk-inline-block tbk-absolute_ tbk-bottom-0 tbk-right-0 tbk-blue-grey-light tbk-text-extra-small">
            {dateCreated}
          </div>
        </div>
      </div>
    </div>
  );
}
