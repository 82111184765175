import React, { useState, useCallback } from 'react';
import Avatar from '../Avatar/Avatar';
import { TimetokenUtils } from '@pubnub/chat';
import Typography from '@material-ui/core/Typography';
// import Image from 'next/image'
// import UnreadIndicator from './unreadIndicator'

// import ToolTip from './toolTip'

interface ChatMenuItemProps {
  avatarUrl?: any;
  text?: any;
  latestMessage?: any;
  present?: any;
  avatarBubblePrecedent?: any;
  count?: any;
  markAsRead?: any;
  markAsReadAction?: any; // = e => {},
  setActiveChannel?: any; // = () => {}
}

export default function ChatMenuItem({
  avatarUrl,
  text,
  latestMessage,
  present,
  avatarBubblePrecedent = '',
  count = '',
  markAsRead = false,
  markAsReadAction = (e: any) => {},
  setActiveChannel = () => {},
}: ChatMenuItemProps) {
  const [showToolTip, setShowToolTip] = useState(false);

  const handleMouseEnter = (e: any) => {
    setShowToolTip(true);
  };
  const handleMouseLeave = (e: any) => {
    setShowToolTip(false);
  };

  const determineUserReadableDate = useCallback(timetoken => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const date = TimetokenUtils.timetokenToDate(timetoken);
    const datetime = `${days[date.getDay()]} ${date.getDate()} ${months[date.getMonth()]}`;

    return datetime;
  }, []);

  let time = '';

  if (latestMessage && latestMessage.message) {
    time = determineUserReadableDate(latestMessage.message.timetoken);
  }

  return (
    <div
      className="tbk-flex tbk-flex-col tbk-cursor-pointer"
      onClick={() => {
        setActiveChannel();
      }}
    >
      <div className="tbk-flex tbk-flex-row tbk-justify-between tbk-items-center tbk-w-full tbk-pl-4_">
        <div className="tbk-min-w-0 tbk-flex tbk-flex-row tbk-py-2 tbk-gap-2_ tbk-h-6 tbk-text-sm tbk-items-center tbk-grow">
          <Avatar
            present={present}
            width={54}
            height={54}
            bubblePrecedent={avatarBubblePrecedent}
            avatarUrl={avatarUrl}
            border
          />
          <div className="tbk-ml-2 tbk-min-w-0 tbk-grow">
            <div className="tbk-flex tbk-justify-between tbk-items-center_">
              <Typography variant="h3" color="primary">
                {text}
              </Typography>
              <div
                className="tbk-inline-block tbk-text-right tbk-text-small tbk-text-blue-grey tbk-px-0.5"
                style={{
                  paddingTop: '2px',
                  paddingBottom: '2px',
                }}
              >
                {time}
              </div>
            </div>
            <Typography
              variant="subtitle1"
              color="primary"
              style={{
                maxHeight: '36px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {latestMessage?.message?.text}
            </Typography>
          </div>
        </div>

        <div className="tbk-flex tbk-flex-row tbk-items-center">
          {/*<UnreadIndicator count={count} />*/}
          {false && markAsRead && (
            <div
              className={`tbk-cursor-pointer tbk-w-4 tbk-h-4 tbk-m-3 tbk-fill-current ${
                showToolTip ? 'tbk-text-sky-700' : 'tbk-text-sky-900'
              }`}
              onClick={(e: any) => markAsReadAction(e)}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className="tbk-relative">
                <div
                  className={`${showToolTip ? 'tbk-block' : 'tbk-hidden'} tbk-bottom-[0px]`}
                  //tip='Read'
                  //messageActionsTip={false}
                />
              </div>
              <svg viewBox="0 0 18 14">
                <path d="M5.79508 10.8749L1.62508 6.70492L0.205078 8.11492L5.79508 13.7049L17.7951 1.70492L16.3851 0.294922L5.79508 10.8749Z" />
              </svg>
            </div>
          )}
        </div>
      </div>
      <hr className="tbk-ml-7 tbk-mt-2 tbk-mb-2" />
    </div>
  );
}
