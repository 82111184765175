import React from 'react';
// import Image from 'next/image'

import avatarPlaceholder from '../../../images/generic-trainerphoto.svg'; //.png

interface AvatarProps {
  avatarUrl?: any;
  present?: any;
  bubblePrecedent?: any;
  width?: any;
  height?: any;
  editIcon?: any;
  editActionHandler?: any;
  border?: any;
}

export default function Avatar({
  avatarUrl,
  present = -1,
  bubblePrecedent = '',
  width = 32,
  height = 32,
  editIcon = false,
  editActionHandler = () => {},
  border = false,
}: AvatarProps) {
  return (
    <div className="relative tbk-shrink-0 tbk-shadow-trube tbk-rounded-full">
      {/*<Image
        src={avatarUrl ? avatarUrl : '/avatars/placeholder.png'}
        alt='User avatar'
        className={`rounded-full ${border && 'border-2 border-white'}`}
        width={width}
        height={height}
      />*/}
      <img
        src={avatarUrl ? avatarUrl : avatarPlaceholder}
        alt="User avatar"
        className={`tbk-w-5 tbk-h-5 tbk-rounded-full ${border && 'tbk-border-2 tbk-border-basic-white'}`}
        width={width}
        height={height}
      />
      {/* Presence Indicator */}
      {present != -1 &&
        bubblePrecedent === '' &&
        (present > 0 ? (
          <div className="tbk-w-[12px] tbk-h-[12px] tbk-rounded-full tbk-border-2 tbk-border-basic-white tbk-bg-success tbk-absolute tbk-left-[22px] tbk-top-[20px]"></div>
        ) : (
          <div className="tbk-w-[12px] tbk-h-[12px] tbk-rounded-full tbk-border-2 tbk-border-basic-white tbk-bg-neutral300 tbk-absolute tbk-left-[22px] tbk-top-[20px]"></div>
        ))}
      {bubblePrecedent !== '' && (
        <div className="tbk-w-[22px] tbk-h-[20px] tbk-rounded-full tbk-text-xs tbk-border tbk-border-navy50 tbk-bg-neutral-100 tbk-absolute tbk-left-[18px] tbk-top-[16px]">
          {bubblePrecedent}
        </div>
      )}
      {editIcon && (
        <div
          className={`tbk-w-[40px] tbk-h-[40px] tbk-rounded-full tbk-text-xs tbk-border-2 tbk-m-2 tbk-border-sky-950 tbk-bg-sky-950 cursor-pointer tbk-absolute tbk--right-[15px] tbk--bottom-[15px]`}
        >
          <div onClick={e => editActionHandler()}>
            {/*<Image
              src={'/icons/edit.svg'}
              alt='Edit'
              className='rounded-full white p-1'
              width={40}
              height={40}
              priority
            />*/}
            <img
              src={'/icons/edit.svg'}
              alt="Edit"
              className="tbk-rounded-full tbk-white tbk-p-1"
              width={40}
              height={40}
              // priority
            />
          </div>
        </div>
      )}
    </div>
  );
}
